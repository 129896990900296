.banner-links {
  .head {
    text-align: center;
  }

  .image {
    img {
      max-width: 250px;
      max-height: 250px;
      height: auto;
      width: auto;
    }
  }

  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}