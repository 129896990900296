.reference-card {
  height: 400px;
  perspective: 900px;
  cursor: default;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 500ms ease-out;
    transform-style: preserve-3d;
  }

  .front, .back {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    backface-visibility: hidden;
    box-shadow: $shadow-01;
    border-radius: $radius-medium;
    background-color: #fff;
  }

  .front {
    &:after {
      content: "\293A";
      position: absolute;
      top: 0px;
      right: 20px;
      width: 60px;
      height: 60px;
      font-size: 60px;
      font-weight: 600;
    }
  }

  .back {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 30px;
    align-items: center;
    transform: rotateY( 180deg );
    //backdrop-filter: saturate(150%) blur(10px);
    background-color: var(--color-secondary);
    color: var(--color-text-light);
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: $radius-medium;
  }

  .reference-card-text {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .reference-card-back-text {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cc0000bf;
    height: auto;
    width: 100%;
  }

  .reference-card-title {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 10px;
    font-weight: 600;
    text-shadow: 2px 2px 0 #212121;
    color: var(--color-text-light);
  }

  &:hover {
    .content {
      transform: rotateY(-180deg) translateZ(-1px);
    }
  }
}

.references-content {
  width: 100%;
}

.references-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@include mobileOnly {
  .reference-card {
    margin: 30px 0;
    width: 100%;
    max-width: 600px;
    .reference-card-title {
      font-size: 28px;
    }
  }
}

@include tabletAndBigger {
  .reference-card {
    width: 400px;
    margin: 30px;
    .reference-card-title {
      font-size: 38px;
    }
  }
}