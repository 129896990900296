.reference-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  .reference-image {
    display: flex;
    justify-content: center;
    max-width: 400px;
    max-height: 300px;
    margin: 35px;

    picture {
      display: inherit;
      justify-content: inherit;
    }

    img {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.reference-title {
  align-self: flex-start;
  padding-left: unset;
  padding-right: unset;
}

.reference-title, .challenge-title, .solution-title {
  margin-bottom: 12px;
}

.reference-images-headline {
  margin-bottom: 25px;
}

.reference-header, .reference-text {
  max-width: 900px !important;
}

.reference-solution, .reference-challenge {
  margin-top: 80px;
}
