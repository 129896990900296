.container {
  @include container;
}

.vc {
  padding-top: $padMargDesktop;
  padding-bottom: $padMargDesktop;
}

section:not(.no-container) > *, header > *, footer > * {
  @include container;
}

.anchor {
  scroll-margin-top: $headerHeight;
}