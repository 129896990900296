.contact-section {
  padding-top: $padMargDesktop;
  .contact-content {
    margin-top: 30px;
  }

  .contact-image {
    display: flex;
    align-items: center;


    .sub-headline {
      margin-bottom: 50px;
    }

    picture {
      display: inherit;
    }

    img {
      max-width: 415px;
      max-height: 450px;
      width: auto;
      height: auto;
    }
  }

  .section-title {
    text-align: center;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }

  .boxes {
    display: flex;
    flex-direction: column;
  }

  .form-wrapper {
    padding: unset;
  }
}

@include mobileOnly {
  .contact-section {
    .boxes {
      flex-direction: column;
      align-items: center;
    }
    .contact-image {
      flex-direction: column;
    }
    .sub-headline {
      text-align: center;
    }
  }
}

@include tabletAndBigger {
  .contact-section {
    .boxes {
      justify-content: space-around;
    }

    .contact-image {
      text-align: left;
      .sub-headline {
        text-align: left;
        max-width: 350px;
        order: 2;
      }
    }

    .sub-headline {
      font-size: 22px;
      margin-left: 50px;
    }
  }
}