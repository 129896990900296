/* CSS variables */
/**********************************************************************************************************************/

/* colors - each color in the following schema: */
// --color-xxx-yyy-rgb
// --color-xxx-yyy-rgba
// --color-xxx-yyy-darkened-rgb
// --color-xxx-yyy-darkened-rgba
// --color-xxx-yyy-lightened-rgb
// --color-xxx-yyy-lightened-rgba

:root {
  @each $key, $value in $colors {
    @include generate-colors($key, $value);
  }
}