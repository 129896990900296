/* auto load css for btn-xxx classes and links */
[class*="btn"] {
  @include btn-creator()
}

/* available buttons */
/*
  btn-full-dark
  btn-full-light
  btn-link-dark
  btn-link-light
*/

/* links in text from editor (w/o class) */
a:not([class]) {
  position: relative;
  text-decoration-line: underline;
  text-decoration-color: var(--color-link-dark);
  transition: .3s;
  &:hover {
    text-decoration-color: var(--color-link-dark-lightened);
  }
}

/*
a:not([class*="btn"]) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color-link-dark);
    bottom: 0;
    left: 0;
    transition: 0.3s;
    transform-origin: bottom right;
    transform: scaleX(0);
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}*/
