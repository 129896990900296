/* breakpoints */
/**********************************************************************************************************************/
$breakpoint-start-iPadMini: 768px;
$breakpoint-start-iPad: 1024px;
$breakpoint-start-mobile-mid: 480px;
$breakpoint-start-mobile-mid2: 576px;

@mixin mobilePortrait {
  @media screen and (max-width: $breakpoint-start-iPadMini - 1px) {
    @content;
  }
}

@mixin mobileOnly {
  @media screen and (max-width: $breakpoint-start-iPad - 1px) {
    @content;
  }
}

@mixin mobileAndTouchOnly {
  @media screen and (max-width: $breakpoint-start-iPad - 1px), (hover: none) {
    @content;
  }
}

@mixin tabletAndBigger {
  @media screen and (min-width: $breakpoint-start-iPad) {
    @content;
  }
}

@mixin tabletAndBiggerNoTouch {
  @media screen and (min-width: $breakpoint-start-iPad) and (hover: hover) {
    @content;
  }

}

@mixin hoverInput {
  @media screen and (hover:hover) {
    @content;
  }
}