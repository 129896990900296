@mixin generate-colors($key, $value) {

  //set rgb color
  --#{$key}: rgb(#{red($value)},#{green($value)},#{blue($value)});

  //set rgba color
  --#{$key}-alpha: rgba(#{red($value)},#{green($value)},#{blue($value)}, 0.5);

  //set darkened color + transparent
  $darkened: darken($value, 10);
  --#{$key}-darkened: rgb(#{red($darkened)},#{green($darkened)},#{blue($darkened)});
  --#{$key}-darkened-alpha: rgba(#{red($darkened)},#{green($darkened)},#{blue($darkened)}, 0.5);

  //set lightened color + transparent
  $lightened: lighten($value, 10);
  --#{$key}-lightened: rgb(#{red($lightened)},#{green($lightened)},#{blue($lightened)});
  --#{$key}-lightened-alpha: rgba(#{red($lightened)},#{green($lightened)},#{blue($lightened)}, 0.5);

}