// general settings
.hero {
  .banner {
    position: relative;
    justify-content: center;
  }
  .banner-bg-image {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      /*animation-name: banner-zoom;*/
      animation-duration: 26s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

    }
    /*&::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(255,255,255,0.1) 65%);
    }*/
  }

  .banner-content {
    align-self: center;
    color: var(--color-highlight);
    text-shadow: 1px 1px var(--color-secondary);
  }

  .headline {
    margin: unset;
    background-color: var(--color-secondary-alpha);
    padding: 20px 25px;

  }

  .banner-subtext {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  .banner-button {
    margin-top: 30px;
  }
}




// mobile only settings
@include mobileOnly {
  main {
    top: 70px;
    position: relative;
  }
  .hero {
    .banner {
      height: calc(100vh - $headerHeight);
    }
    .banner-headline {
      font-size: 36px;
    }
  }
}

// tablet/desktop settings only
@include tabletAndBigger {
  .hero {
    .banner {
      height: calc(100vh - 130px);
    }

    .banner-headline {
      font-size: 64px;
    }
  }
}

// special config for slim landscape
@media screen and (max-height: 480px) {
  .hero {
    .banner {
      height: 120vh;
    }
    .banner-image {
      height: 120vh;
      &::before {
        height: 120vh;
      }
    }
  }
}