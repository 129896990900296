.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(150%) blur(10px);

  &.modal-visible {
    display: flex;
  }

  .modal-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
  };

  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #F5F6F7;
    margin: 0 10px;
    border-radius: 25px;
  }

  .modal-title {
    margin-bottom: 5px;
    font-weight: 700;
  }

  .modal-close {
    border-top: 1px solid grey;
    padding: 15px;
    text-align: center;
    color: #007aff;
    font-weight: 600;
    cursor: pointer;
  }
}

@include mobileOnly {
  .form-modal {
    font-size: 14px;
  }
}
