$borderSize: 14px;
$borderSize2: calc($borderSize / 2);
$topBorder: calc($borderSize + $borderSize2);
$gap: 90px;

$pointSpace: calc($gap/2 + $borderSize2);

.timeline {
  position: relative;

  &::before {
    content: '';
    background-color: var(--color-secondary);
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;
  }

  .timeline-item {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 400px;
    height: fit-content;
    background-color: #fff;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .timeline-element-year {
    padding: 15px 15px;
    background-color: var(--color-secondary);
    color: var(--color-text-light);
    font-weight: 800;
    font-size: 22px;

  }
  .timeline-element-description {
    padding: 15px 15px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .timeline-element-image {
    display: flex;
    justify-content: center;

    max-height: 350px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    picture{
      display: inherit;
      justify-content: inherit;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@include mobileOnly {
  .timeline {
    display: block;
  }
}

@include tabletAndBigger {
  .timeline {
    display: grid;
    grid-template-columns: [left]1fr [right]1fr ;
    gap: 0 $gap;

    .timeline-item {
      &:nth-child(odd) {
        grid-column: left;
        .timeline-element-year{

          &::after {
            border-left-width: $borderSize;
            border-left-style: solid;
            left: 100%;
          }

          &::before {
            right: -$pointSpace;
          }

        }
      }

      &:nth-child(even) {
        grid-column: right;
        margin-top: 90px;

        .timeline-element-year{

          &::after {
            border-right-width: $borderSize;
            border-right-style: solid;
            right: 100%;
          }

          &::before {
            left: -$pointSpace;
          }

        }
      }
    }

    .timeline-element-year {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-color: var(--color-secondary);
        border-top: $borderSize solid transparent;
        border-bottom: $borderSize solid transparent;
      }
      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-radius: 50%;
        top: $topBorder;
        border: $borderSize2 solid var(--color-primary);
      }
    }
  }
}