@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../../../fonts/Roboto/Roboto-Regular.woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-display: swap;
  src: local(''),
  url('../../../fonts/Roboto/Roboto-Medium.woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../../../fonts/Roboto/Roboto-Bold.woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-display: swap;
  src: local(''),
  url('../../../fonts/Roboto/Roboto-Black.woff');
}