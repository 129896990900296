.download-section {
  .headline {
    margin-bottom: 15px;
  };
  .tex-field {
    margin-bottom: 15px;
  };
  a {
    display: block;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &::before {
      content: url("../../../images/pdf.svg");
      width: 25px;
      height: auto;
      display: inline-block;
    }
  }
};

@include mobileOnly {
  .download-section {
    a {
      font-size: 18px;
    }
  };
};