$imageMaxHeight: $headerHeight - 30px;
$itemFontSize: 16px;
$menuHeight: 60px;
$listItemHeight: 60px;

header {
  height: $headerHeight;
  color: var(--color-text-light);
  background-color: var(--color-header-bg);

  .header-wrapper {
    flex-direction: row;

    .logo-link {
      display: flex;
      align-items: center;
      height: 100%;

      picture {
        display: inherit;
      }

      img {
        width: auto;
        height: auto;
        max-width: 35vw;
        max-height: $imageMaxHeight;
        margin-right: 20px;
      }
    }
  }
}

@include mobileAndTouchOnly{
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    .header-wrapper {
      justify-content: flex-start;
      padding-right: 70px;
    }
    .header-contact {
      display: none;
    }
  }
  .nav-wrapper {
    position: fixed;
    height: 70px;
    top: 0;
    right: 0;
    z-index: 2;
    padding-right: 10px;
    nav {
      display: flex;
      flex-grow: 1;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      color: var(--color-text-light);
      .menu-main {
        position: fixed;
        overflow-y: auto;
        z-index: 1;
        width: 400px;
        height: calc(100% - $headerHeight);
        max-width: 100%;
        top: $headerHeight;
        right: 0;
        padding-left: $padMargDefault + 5px;
        padding-right: $padMargDefault + 5px;
        transform: translateX(110%);
        transition: transform .5s ease-out;
        background-color: var(--color-header-nav-mobile-main);
        box-shadow: 0 6px 6px 2px #b0b0b0;

        &.sd-active {
          transform: translateX(0%);
        }
      }

      .list-item {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        &.list-item-active, &:hover, .list-item-active, :hover{
          > .list-link {
            color: var(--color-primary);
          }
        }
        &.dropdown {

        }

        .list-link {
          display: flex;
          align-items: center;
          height: $listItemHeight;
          font-size: 18px;
          font-weight: 500;
          max-width: 75%;
        }

        .list-toggler {

        }
      }
      .sublist {
        width: 100%;
        background-color: var(--color-header-nav-mobile-sub);
        .list-link {
          max-width: unset;
          padding-left: 20px;
        }
      }
    }
  }
}
@include  tabletAndBiggerNoTouch {
  header {
    .header-wrapper {
      justify-content: space-between;
    }
  }
  .nav-wrapper {
    position: sticky;
    top: 0;
    height: $menuHeight;
    z-index: 10;
    background-color: var(--color-primary);
    color: var(--color-text-dark);
    font-size: 20px;
    font-weight: 500;

    .nav-main {
      height: 100%;
    }

    .menu-main {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }

    .list-items-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .list {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      .list-item {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        margin-left: $padMargDefault;

        &:hover + .menu-overlay{

          display: block;
        }

        &.list-item-active, &:hover, .list-item-active, :hover{
          > .list-link {
            color: var(--color-text-light);
          }
        }

        .list-link {
          display: flex;
          align-items: center;
          padding-left: $padMargDefault;
          height: 100%;
        }
        &:not(:last-child) {
          margin-right: $padMargDefault;
          .list-link {
            padding-right: $padMargDefault;
          }
        }

        .list-toggler {
          display: none;
        }
      }
    }
    .dropdown {
      &:hover {
        .sublist {
          height: 100%;
        }
      }
    }

    .sublist {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      left: 0;
      right: 0;
      height: 0;
      top: $headerHeight;
      background-color: var(--color-header-nav-desktop-sub);
      color: var(--color-text-light);
      transition: height .3s ease-out;
      .list-items-wrapper {
        justify-content: center;
      }
    }


  }
}