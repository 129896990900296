$slideWidth: 250px;
$imageMaxHeight: 100px;
$imageMaxWidth: calc($slideWidth - 50px);
$slideHeight: calc($imageMaxHeight * 1.5);
$sliderHeight: calc($slideHeight + 100px);

@keyframes slider-scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slider {
  position: relative;
  overflow: hidden;
  height: $sliderHeight;

  &::before,
  &::after {
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 10%;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    position: absolute;
    display: flex;
    justify-content: space-around;
    min-width: 200%;

    animation-name: slider-scroll;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    
  }

  .slide {
    display: flex;
    flex-direction: column;
    height: $slideHeight;
    width: $slideWidth;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
  }

  img {
    width: auto;
    height: auto;
    max-width: $imageMaxWidth;
    max-height: $imageMaxHeight;
  }
  .technology-name {
    text-align: center;
  }
  .technology-header {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@include mobileOnly {
  .slider{
    .technology-header {
      margin-left: $padMargDefault;
      margin-right: $padMargDefault;
    }
  }
}