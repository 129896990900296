.references-header {
  .headline {
    text-align: center;
  }
}

.hero.reference {
  .headline {
    @include highlight;
  }
}