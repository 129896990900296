.flash {
  position: fixed;
  z-index: 5;
  .close {
    margin-left: 15px;
    color: red;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
}
