.section-odd {
  .link-box, .info-box, .form-box, .project-plan-box {
    background-color: var(--color-section-even);
  }
}

section:not(.section-odd) {
  .link-box, .info-box, .form-box, .project-plan-box {
    background-color: var(--color-section-odd);
  }
}

.link-box, .info-box, .form-box, .project-plan-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px;
  transition: transform 0.25s ease-in-out;
  border: 1px solid #ccc;

  .lo {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
}

.link-box {
  width: 250px;
  margin: 30px;
  &:hover {
    transform: scale(1.1);
  }
}

.form-box {
  margin-bottom: $padMargDesktop;
}

.info-box {
  width: 100%;
}

.project-plan {
  display: flex;
  flex-direction: column;
}

.project-plan-box {
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-between;

  .box-count {
    padding: 30px;
    border-right: 2px solid #212121;
    font-size: 48px;
    font-weight: 700;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .title {
    display: flex;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: left;
    &::before {
      display: inline-block;
      counter-increment: counter01;
      content: counter(counter01) ". \00a0";
      @include highlight;
    }
  }

  .lo {
    margin-right: 20px;
    margin-left: 10px;
    align-self: flex-end;
  }

  li {
    list-style-type: disclosure-closed;
    list-style-position: inside;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.box-with-counter {
  counter-reset: counter01;
}

@include mobileOnly {
  .info-box {
    max-width: 600px;
    margin-bottom: 50px;
  }
  .project-plan-box {
    .title {
      font-size: 22px;
    }
  }

  .project-plan-box-wrapper {
    $arrowSize: 100px;
    display: flex;
    flex-direction: column;

    &::before, &::after {
      background-image: var(--arrow);
      display:block;
      height:$arrowSize;
      width:$arrowSize;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:not(:last-child) {
      &::after {
        content: "";
      }
      &:nth-child(even) {
        &::after {
          transform: scale3d(-1, 1, 1) rotate(115deg);
          align-self: flex-start;
          margin-left: 20%;
        }
      }
      &:nth-child(odd) {
        &::after {
          transform: rotate(115deg);
          align-self: flex-end;
          margin-right: 20%;
        }
      }
    }
  }
}

@include tabletAndBigger {
  $arrowSize: 200px;
  $arrowMarginInside: 75px;
  $arrowMarginOutside: 150px;

  .info-box {
    margin-bottom: $padMargDesktop;
    &:first-child {
      margin-right: $padMargDesktop;;;
    }
  }

  .project-plan-box-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &::before, &::after {
      background-image: var(--arrow);
      display:block;
      height:$arrowSize;
      width:$arrowSize;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:nth-child(even) {
      &::before {
        content: "";
        transform: scale3d(1, -1, 1) rotate(355deg);
        margin-right: $arrowMarginInside;
        margin-left: $arrowMarginOutside;
      }
    }

    &:nth-child(2n+3) {
      &::after {
        content: "";
        transform: scale3d(-1, -1, 1) rotate(355deg);
        margin-left: $arrowMarginInside;
        margin-right: $arrowMarginInside;
      }
    }
  }

  .project-plan-box {
    .title {
      font-size: 30px;
    }
  }
}

@include mobilePortrait {
  .project-plan-box {
    flex-direction: column;
    align-items: flex-start;
    .lo {
      align-self: center;
    }
  }
}