/**********************************************************************************************************************/
/* Base Variables
/**********************************************************************************************************************/

/* colors */
/**********************************************************************************************************************/

// color map (will be converted to css vars in rgb, lightened, darkened, transparent)
$colors: (
  // main colors
  'color-primary': #ffcc00,
  'color-secondary': #363636,
  'color-tertiary': #00aa55,

  // section colors
  'color-section-odd': #ffffff,
  'color-section-even': #F5F6F7, //e5e5e5
  'color-footer': #363636,

  // text colors
  'color-text-dark': #363636,
  'color-text-light': #fff,
  'color-text-grey': #666,

  // link colors
  'color-link-dark': #ffcc00,
  'color-link-light': #eee,

  // button colors
  'color-btn-dark-bg': #ffcc00,
  'color-btn-dark-border': #ffcc00,
  'color-btn-dark-text': #363636,

  'color-btn-light-bg': #fff,
  'color-btn-light-border': #fff,
  'color-btn-light-text': #000,

  // highlight color for highlight class
  'color-highlight': #ffcc00,

  // header colors
  'color-header-bg': #363636,
  'color-header-nav-mobile-main': #363636,
  'color-header-nav-mobile-sub': #fff,
  'color-header-nav-desktop-sub': #fff,

  // notification colors
  'color-notification-success': #008000,
  'color-notification-info': #ffff00,
  'color-notification-warning': #ff0000,
);

/* default padding/margin */
/**********************************************************************************************************************/
$padMargDefault: 10px;
$padMargDesktop: 80px;

/* header style */
/**********************************************************************************************************************/
$header: "1-1";
$headerHeight: 70px;

/* button style */
/**********************************************************************************************************************/
//TODO: not in use at the moment; make several button styles?
$btn-style: 1;

/* border radius */
/**********************************************************************************************************************/
$radius-btn: 0px;
$radius-small: 5px;
$radius-medium: 25px;
$radius-round: 50%;

/* shadows */
/**********************************************************************************************************************/
$shadow-01: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
$shadow-02: 17px 20px 40px rgba(0,0,0,.21);
$shadow-03: 0 -2px 5px #333;
$shadow-04: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;

/* gradients */
/**********************************************************************************************************************/
$gradient-01: linear-gradient(to top left , var(--color-primary) 40%, var(--color-secondary));
$gradient-02: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);

/* odd section handling */
/**********************************************************************************************************************/
$oddSections: true;

/* base path assets */
/**********************************************************************************************************************/
$base-path: './assets/';