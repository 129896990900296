.form-box {
  max-width: 900px !important;
}
form {
  accent-color: var(--color-notification-success);
  .captcha > div{
    display: flex;
    flex-direction: column;

    input {
      order: 3;
      max-width: 270px;
    }

    label {
      order: 1;
    }

    .captcha-img-section {
      display: flex;
    }
  }

  .form-row {
    width: 100%;
    margin: 25px 0;
  }

  .invalid-feedback {
    padding-left: 16px;
    order: 4;
    color: #dc3545;
  }

  .check > div {
    display: flex;
    flex-direction: column;
    label {
      max-width: 90%;
    }
    input {
      width: 25px;
      height: 25px;
    }
  }

  input:not([type=checkbox]), textarea, select {
    width: 100%;
    border: 1px solid var(--color-secondary);
    font-size: 18px;
    padding: 8px;

  }

  input, textarea {
    margin-top: 10px;
  }

  textarea {
    resize: none;
    height: 200px;
  }

  label, legend {
    &.required {
      &::after {
        content: " *";
        color: var(--color-notification-warning);
      };
    };
  }

  .form-mandatory {
    &::before {
      content: "*";
      color: var(--color-notification-warning);
    }
  }

  .refresh-icon {
    font-size: 64px;
    line-height: 42px;
    cursor: pointer;
    margin-left: 5px;
  }

  .form-control.is-invalid, .was-validated .form-control:invalid, .is-invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right calc(0.375em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border: 1px solid #dc3545;
  }
}
