.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  width: 100%;
}

.product-box {
  width: 300px;
  height: 500px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0 auto;

  .image-container {
    width: 100%;
    height: 30%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
    padding-top: 20px;

    .title {
      height: 2.6em;
    }
    .description {
      flex-grow: 2;
      text-align: unset;
      overflow: hidden;
    }

    .link-to-shop {
    }
  }
}