@charset "UTF-8";
/**********************************************************************************************************************/
/* Base Variables
/**********************************************************************************************************************/
/* colors */
/**********************************************************************************************************************/
/* default padding/margin */
/**********************************************************************************************************************/
/* header style */
/**********************************************************************************************************************/
/* button style */
/**********************************************************************************************************************/
/* border radius */
/**********************************************************************************************************************/
/* shadows */
/**********************************************************************************************************************/
/* gradients */
/**********************************************************************************************************************/
/* odd section handling */
/**********************************************************************************************************************/
/* base path assets */
/**********************************************************************************************************************/
[data-animation-target] {
  opacity: 0;
}

[data-animation-animation-name-value=alternating-horizontal] [data-animation-target].sd-observed:nth-child(odd) {
  animation-name: anim--from-left;
}
[data-animation-animation-name-value=alternating-horizontal] [data-animation-target].sd-observed:nth-child(even) {
  animation-name: anim--from-right;
}

[data-animation-animation-name-value=alternating-horizontal-reverse] [data-animation-target].sd-observed:nth-child(odd) {
  animation-name: anim--from-right;
}
[data-animation-animation-name-value=alternating-horizontal-reverse] [data-animation-target].sd-observed:nth-child(even) {
  animation-name: anim--from-left;
}

[data-animation-animation-name-value=alternating-vertical] [data-animation-target].sd-observed:nth-child(odd) {
  animation-name: anim--from-bottom;
}
[data-animation-animation-name-value=alternating-vertical] [data-animation-target].sd-observed:nth-child(even) {
  animation-name: anim--from-top;
}

@keyframes anim--from-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes anim--from-right {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes anim--from-bottom {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes anim--appear {
  100% {
    opacity: 1;
  }
}
@keyframes banner-zoom {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@keyframes anim--short-hop {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
/* flip card indicator */
@keyframes flip-card {
  50% {
    rotate: -5deg;
  }
  75% {
    rotate: 5deg;
  }
  100% {
    rotate: 0deg;
  }
}
/* breakpoints */
/**********************************************************************************************************************/
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../../../fonts/Roboto/Roboto-Regular.woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-display: swap;
  src: local(""), url("../../../fonts/Roboto/Roboto-Medium.woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../../../fonts/Roboto/Roboto-Bold.woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-display: swap;
  src: local(""), url("../../../fonts/Roboto/Roboto-Black.woff");
}
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 + 3. smooth font for apple
 */
html {
  line-height: 1.3; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -webkit-font-smoothing: antialiased; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margins
 */
body, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.3; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

* {
  box-sizing: border-box;
}

*::marker {
  display: none;
}

ol, ul, li, a {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

blockquote, q {
  quotes: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* TODO delete, when all link behaviour is set in each component */
a, a:link, a:visited, a:hover, a:active {
  all: unset;
  cursor: pointer;
  display: inline-block;
}

address {
  font-style: unset;
}

/* CSS variables */
/**********************************************************************************************************************/
/* colors - each color in the following schema: */
:root {
  --color-primary: rgb(255,204,0);
  --color-primary-alpha: rgba(255,204,0, 0.5);
  --color-primary-darkened: rgb(204,163,0);
  --color-primary-darkened-alpha: rgba(204,163,0, 0.5);
  --color-primary-lightened: rgb(255,214,51);
  --color-primary-lightened-alpha: rgba(255,214,51, 0.5);
  --color-secondary: rgb(54,54,54);
  --color-secondary-alpha: rgba(54,54,54, 0.5);
  --color-secondary-darkened: rgb(29,29,29);
  --color-secondary-darkened-alpha: rgba(29,29,29, 0.5);
  --color-secondary-lightened: rgb(80,80,80);
  --color-secondary-lightened-alpha: rgba(80,80,80, 0.5);
  --color-tertiary: rgb(0,170,85);
  --color-tertiary-alpha: rgba(0,170,85, 0.5);
  --color-tertiary-darkened: rgb(0,119,60);
  --color-tertiary-darkened-alpha: rgba(0,119,60, 0.5);
  --color-tertiary-lightened: rgb(0,221,111);
  --color-tertiary-lightened-alpha: rgba(0,221,111, 0.5);
  --color-section-odd: rgb(255,255,255);
  --color-section-odd-alpha: rgba(255,255,255, 0.5);
  --color-section-odd-darkened: rgb(230,230,230);
  --color-section-odd-darkened-alpha: rgba(230,230,230, 0.5);
  --color-section-odd-lightened: rgb(255,255,255);
  --color-section-odd-lightened-alpha: rgba(255,255,255, 0.5);
  --color-section-even: rgb(245,246,247);
  --color-section-even-alpha: rgba(245,246,247, 0.5);
  --color-section-even-darkened: rgb(217,221,224);
  --color-section-even-darkened-alpha: rgba(217,221,224, 0.5);
  --color-section-even-lightened: rgb(255,255,255);
  --color-section-even-lightened-alpha: rgba(255,255,255, 0.5);
  --color-footer: rgb(54,54,54);
  --color-footer-alpha: rgba(54,54,54, 0.5);
  --color-footer-darkened: rgb(29,29,29);
  --color-footer-darkened-alpha: rgba(29,29,29, 0.5);
  --color-footer-lightened: rgb(80,80,80);
  --color-footer-lightened-alpha: rgba(80,80,80, 0.5);
  --color-text-dark: rgb(54,54,54);
  --color-text-dark-alpha: rgba(54,54,54, 0.5);
  --color-text-dark-darkened: rgb(29,29,29);
  --color-text-dark-darkened-alpha: rgba(29,29,29, 0.5);
  --color-text-dark-lightened: rgb(80,80,80);
  --color-text-dark-lightened-alpha: rgba(80,80,80, 0.5);
  --color-text-light: rgb(255,255,255);
  --color-text-light-alpha: rgba(255,255,255, 0.5);
  --color-text-light-darkened: rgb(230,230,230);
  --color-text-light-darkened-alpha: rgba(230,230,230, 0.5);
  --color-text-light-lightened: rgb(255,255,255);
  --color-text-light-lightened-alpha: rgba(255,255,255, 0.5);
  --color-text-grey: rgb(102,102,102);
  --color-text-grey-alpha: rgba(102,102,102, 0.5);
  --color-text-grey-darkened: rgb(77,77,77);
  --color-text-grey-darkened-alpha: rgba(77,77,77, 0.5);
  --color-text-grey-lightened: rgb(128,128,128);
  --color-text-grey-lightened-alpha: rgba(128,128,128, 0.5);
  --color-link-dark: rgb(255,204,0);
  --color-link-dark-alpha: rgba(255,204,0, 0.5);
  --color-link-dark-darkened: rgb(204,163,0);
  --color-link-dark-darkened-alpha: rgba(204,163,0, 0.5);
  --color-link-dark-lightened: rgb(255,214,51);
  --color-link-dark-lightened-alpha: rgba(255,214,51, 0.5);
  --color-link-light: rgb(238,238,238);
  --color-link-light-alpha: rgba(238,238,238, 0.5);
  --color-link-light-darkened: rgb(213,213,213);
  --color-link-light-darkened-alpha: rgba(213,213,213, 0.5);
  --color-link-light-lightened: rgb(255,255,255);
  --color-link-light-lightened-alpha: rgba(255,255,255, 0.5);
  --color-btn-dark-bg: rgb(255,204,0);
  --color-btn-dark-bg-alpha: rgba(255,204,0, 0.5);
  --color-btn-dark-bg-darkened: rgb(204,163,0);
  --color-btn-dark-bg-darkened-alpha: rgba(204,163,0, 0.5);
  --color-btn-dark-bg-lightened: rgb(255,214,51);
  --color-btn-dark-bg-lightened-alpha: rgba(255,214,51, 0.5);
  --color-btn-dark-border: rgb(255,204,0);
  --color-btn-dark-border-alpha: rgba(255,204,0, 0.5);
  --color-btn-dark-border-darkened: rgb(204,163,0);
  --color-btn-dark-border-darkened-alpha: rgba(204,163,0, 0.5);
  --color-btn-dark-border-lightened: rgb(255,214,51);
  --color-btn-dark-border-lightened-alpha: rgba(255,214,51, 0.5);
  --color-btn-dark-text: rgb(54,54,54);
  --color-btn-dark-text-alpha: rgba(54,54,54, 0.5);
  --color-btn-dark-text-darkened: rgb(29,29,29);
  --color-btn-dark-text-darkened-alpha: rgba(29,29,29, 0.5);
  --color-btn-dark-text-lightened: rgb(80,80,80);
  --color-btn-dark-text-lightened-alpha: rgba(80,80,80, 0.5);
  --color-btn-light-bg: rgb(255,255,255);
  --color-btn-light-bg-alpha: rgba(255,255,255, 0.5);
  --color-btn-light-bg-darkened: rgb(230,230,230);
  --color-btn-light-bg-darkened-alpha: rgba(230,230,230, 0.5);
  --color-btn-light-bg-lightened: rgb(255,255,255);
  --color-btn-light-bg-lightened-alpha: rgba(255,255,255, 0.5);
  --color-btn-light-border: rgb(255,255,255);
  --color-btn-light-border-alpha: rgba(255,255,255, 0.5);
  --color-btn-light-border-darkened: rgb(230,230,230);
  --color-btn-light-border-darkened-alpha: rgba(230,230,230, 0.5);
  --color-btn-light-border-lightened: rgb(255,255,255);
  --color-btn-light-border-lightened-alpha: rgba(255,255,255, 0.5);
  --color-btn-light-text: rgb(0,0,0);
  --color-btn-light-text-alpha: rgba(0,0,0, 0.5);
  --color-btn-light-text-darkened: rgb(0,0,0);
  --color-btn-light-text-darkened-alpha: rgba(0,0,0, 0.5);
  --color-btn-light-text-lightened: rgb(26,26,26);
  --color-btn-light-text-lightened-alpha: rgba(26,26,26, 0.5);
  --color-highlight: rgb(255,204,0);
  --color-highlight-alpha: rgba(255,204,0, 0.5);
  --color-highlight-darkened: rgb(204,163,0);
  --color-highlight-darkened-alpha: rgba(204,163,0, 0.5);
  --color-highlight-lightened: rgb(255,214,51);
  --color-highlight-lightened-alpha: rgba(255,214,51, 0.5);
  --color-header-bg: rgb(54,54,54);
  --color-header-bg-alpha: rgba(54,54,54, 0.5);
  --color-header-bg-darkened: rgb(29,29,29);
  --color-header-bg-darkened-alpha: rgba(29,29,29, 0.5);
  --color-header-bg-lightened: rgb(80,80,80);
  --color-header-bg-lightened-alpha: rgba(80,80,80, 0.5);
  --color-header-nav-mobile-main: rgb(54,54,54);
  --color-header-nav-mobile-main-alpha: rgba(54,54,54, 0.5);
  --color-header-nav-mobile-main-darkened: rgb(29,29,29);
  --color-header-nav-mobile-main-darkened-alpha: rgba(29,29,29, 0.5);
  --color-header-nav-mobile-main-lightened: rgb(80,80,80);
  --color-header-nav-mobile-main-lightened-alpha: rgba(80,80,80, 0.5);
  --color-header-nav-mobile-sub: rgb(255,255,255);
  --color-header-nav-mobile-sub-alpha: rgba(255,255,255, 0.5);
  --color-header-nav-mobile-sub-darkened: rgb(230,230,230);
  --color-header-nav-mobile-sub-darkened-alpha: rgba(230,230,230, 0.5);
  --color-header-nav-mobile-sub-lightened: rgb(255,255,255);
  --color-header-nav-mobile-sub-lightened-alpha: rgba(255,255,255, 0.5);
  --color-header-nav-desktop-sub: rgb(255,255,255);
  --color-header-nav-desktop-sub-alpha: rgba(255,255,255, 0.5);
  --color-header-nav-desktop-sub-darkened: rgb(230,230,230);
  --color-header-nav-desktop-sub-darkened-alpha: rgba(230,230,230, 0.5);
  --color-header-nav-desktop-sub-lightened: rgb(255,255,255);
  --color-header-nav-desktop-sub-lightened-alpha: rgba(255,255,255, 0.5);
  --color-notification-success: rgb(0,128,0);
  --color-notification-success-alpha: rgba(0,128,0, 0.5);
  --color-notification-success-darkened: rgb(0,77,0);
  --color-notification-success-darkened-alpha: rgba(0,77,0, 0.5);
  --color-notification-success-lightened: rgb(0,179,0);
  --color-notification-success-lightened-alpha: rgba(0,179,0, 0.5);
  --color-notification-info: rgb(255,255,0);
  --color-notification-info-alpha: rgba(255,255,0, 0.5);
  --color-notification-info-darkened: rgb(204,204,0);
  --color-notification-info-darkened-alpha: rgba(204,204,0, 0.5);
  --color-notification-info-lightened: rgb(255,255,51);
  --color-notification-info-lightened-alpha: rgba(255,255,51, 0.5);
  --color-notification-warning: rgb(255,0,0);
  --color-notification-warning-alpha: rgba(255,0,0, 0.5);
  --color-notification-warning-darkened: rgb(204,0,0);
  --color-notification-warning-darkened-alpha: rgba(204,0,0, 0.5);
  --color-notification-warning-lightened: rgb(255,51,51);
  --color-notification-warning-lightened-alpha: rgba(255,51,51, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1600px;
}
@media screen and (max-width: 1023px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.vc {
  padding-top: 80px;
  padding-bottom: 80px;
}

section:not(.no-container) > *, header > *, footer > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1600px;
}
@media screen and (max-width: 1023px) {
  section:not(.no-container) > *, header > *, footer > * {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  section:not(.no-container) > *, header > *, footer > * {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.anchor {
  scroll-margin-top: 70px;
}

/* fonts */
/**********************************************************************************************************************/
/*
text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
-webkit-text-stroke: 0.1px #000;
*/
body {
  font-family: Roboto, sans-serif;
  font-size: 19px;
  color: var(--color-text-dark);
}

@media screen and (max-width: 1023px) {
  main {
    font-size: 16px;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-family: Roboto, sans-serif;
}

.headline {
  margin-top: 12px;
  margin-bottom: 32px;
}

.sub-headline {
  font-weight: 600;
  color: var(--color-text-grey);
  text-align: center;
}

.highlight {
  color: var(--color-primary);
}

::selection {
  color: var(--color-text-light);
  background-color: var(--color-highlight);
}

p {
  text-align: justify;
}

main li {
  list-style: inside;
}

/* font sizes */
/**********************************************************************************************************************/
@media screen and (max-width: 1023px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  .sub-headline {
    font-size: 22px;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 54px;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 30px;
  }
  h5 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.2rem;
  }
  .sub-headline {
    font-size: 30px;
  }
}
/* auto load css for btn-xxx classes and links */
[class*=btn] {
  /* any link that has a class */
}
[class*=btn][class] {
  padding-bottom: 9px;
  padding-top: 9px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 18px;
  border-radius: 0px;
}
@media screen and (max-width: 1023px) {
  [class*=btn][class] {
    width: 100%;
    max-width: 390px;
  }
}
@media screen and (min-width: 1024px) {
  [class*=btn][class] {
    min-width: 160px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
[class*=btn][class*=-full][class*=-highlight] {
  background-image: linear-gradient(to top left, var(--color-primary) 40%, var(--color-secondary));
  color: var(--color-btn-dark-text);
  text-shadow: 1px 1px 0 #212121;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
}
@media screen and (min-width: 1024px) {
  [class*=btn][class*=-full][class*=-highlight] {
    padding-left: 19px;
    padding-right: 19px;
  }
}
@media (hover: hover) {
  [class*=btn][class*=-full][class*=-highlight]:hover {
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }
}
[class*=btn][class*=-full][class*=-dark] {
  background-color: var(--color-btn-dark-bg);
  border-color: var(--color-btn-dark-border);
  color: var(--color-btn-dark-text);
  font-weight: 600;
}
@media (hover: hover) {
  [class*=btn][class*=-full][class*=-dark]:hover {
    background-color: var(--color-btn-dark-bg-lightened);
    border-color: var(--color-btn-dark-border-lightened);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }
}
[class*=btn][class*=-full][class*=-light] {
  background-color: var(--color-btn-light-bg);
  border-color: var(--color-btn-light-border);
  color: var(--color-btn-light-text);
}
@media (hover: hover) {
  [class*=btn][class*=-full][class*=-light]:hover {
    background-color: var(--color-btn-light-bg-darkened);
    border-color: var(--color-btn-light-border-darkened);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }
}
[class*=btn][class*=-link] {
  border-color: transparent;
}
[class*=btn][class*=-link][class*=-dark] {
  color: var(--color-text-dark);
  font-weight: 600;
}
@media (hover: hover) {
  [class*=btn][class*=-link][class*=-dark]:hover {
    color: var(--color-btn-dark-bg);
  }
}
[class*=btn][class*=-link][class*=-light] {
  color: var(--color-link-light);
  text-shadow: 1px 1px 0 #212121;
}
@media (hover: hover) {
  [class*=btn][class*=-link][class*=-light]:hover {
    color: var(--color-btn-dark-bg);
  }
}

/* available buttons */
/*
  btn-full-dark
  btn-full-light
  btn-link-dark
  btn-link-light
*/
/* links in text from editor (w/o class) */
a:not([class]) {
  position: relative;
  text-decoration-line: underline;
  text-decoration-color: var(--color-link-dark);
  transition: 0.3s;
}
a:not([class]):hover {
  text-decoration-color: var(--color-link-dark-lightened);
}

/*
a:not([class*="btn"]) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color-link-dark);
    bottom: 0;
    left: 0;
    transition: 0.3s;
    transform-origin: bottom right;
    transform: scaleX(0);
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}*/
.section-odd .link-box, .section-odd .info-box, .section-odd .form-box, .section-odd .project-plan-box {
  background-color: var(--color-section-even);
}

section:not(.section-odd) .link-box, section:not(.section-odd) .info-box, section:not(.section-odd) .form-box, section:not(.section-odd) .project-plan-box {
  background-color: var(--color-section-odd);
}

.link-box, .info-box, .form-box, .project-plan-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px;
  transition: transform 0.25s ease-in-out;
  border: 1px solid #ccc;
}
.link-box .lo, .info-box .lo, .form-box .lo, .project-plan-box .lo {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
}

.link-box {
  width: 250px;
  margin: 30px;
}
.link-box:hover {
  transform: scale(1.1);
}

.form-box {
  margin-bottom: 80px;
}

.info-box {
  width: 100%;
}

.project-plan {
  display: flex;
  flex-direction: column;
}

.project-plan-box {
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-between;
}
.project-plan-box .box-count {
  padding: 30px;
  border-right: 2px solid #212121;
  font-size: 48px;
  font-weight: 700;
}
.project-plan-box .content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.project-plan-box .title {
  display: flex;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
}
.project-plan-box .title::before {
  display: inline-block;
  counter-increment: counter01;
  content: counter(counter01) ".  ";
  color: var(--color-primary);
}
.project-plan-box .lo {
  margin-right: 20px;
  margin-left: 10px;
  align-self: flex-end;
}
.project-plan-box li {
  list-style-type: disclosure-closed;
  list-style-position: inside;
}
.project-plan-box li:not(:last-child) {
  margin-bottom: 5px;
}

.box-with-counter {
  counter-reset: counter01;
}

@media screen and (max-width: 1023px) {
  .info-box {
    max-width: 600px;
    margin-bottom: 50px;
  }
  .project-plan-box .title {
    font-size: 22px;
  }
  .project-plan-box-wrapper {
    display: flex;
    flex-direction: column;
  }
  .project-plan-box-wrapper::before, .project-plan-box-wrapper::after {
    background-image: var(--arrow);
    display: block;
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .project-plan-box-wrapper:not(:last-child)::after {
    content: "";
  }
  .project-plan-box-wrapper:not(:last-child):nth-child(even)::after {
    transform: scale3d(-1, 1, 1) rotate(115deg);
    align-self: flex-start;
    margin-left: 20%;
  }
  .project-plan-box-wrapper:not(:last-child):nth-child(odd)::after {
    transform: rotate(115deg);
    align-self: flex-end;
    margin-right: 20%;
  }
}
@media screen and (min-width: 1024px) {
  .info-box {
    margin-bottom: 80px;
  }
  .info-box:first-child {
    margin-right: 80px;
  }
  .project-plan-box-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .project-plan-box-wrapper::before, .project-plan-box-wrapper::after {
    background-image: var(--arrow);
    display: block;
    height: 200px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .project-plan-box-wrapper:nth-child(even)::before {
    content: "";
    transform: scale3d(1, -1, 1) rotate(355deg);
    margin-right: 75px;
    margin-left: 150px;
  }
  .project-plan-box-wrapper:nth-child(2n+3)::after {
    content: "";
    transform: scale3d(-1, -1, 1) rotate(355deg);
    margin-left: 75px;
    margin-right: 75px;
  }
  .project-plan-box .title {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .project-plan-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .project-plan-box .lo {
    align-self: center;
  }
}
@media screen and (max-width: 1023px), (hover: none) {
  .sublist-toggler {
    display: flex;
    width: 27px;
    height: 27px;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
  }
  .sublist-toggler::before, .sublist-toggler::after {
    position: absolute;
    display: block;
    background-color: var(--color-primary);
    content: "";
    border-radius: 2px;
  }
  .sublist-toggler::after {
    width: 17px;
    height: 2px;
    transition: transform 0.3s ease-in-out;
  }
  .sublist-toggler::before {
    width: 2px;
    height: 17px;
    opacity: 1;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .accordion-panel.sd-active .sublist-toggler::before {
    transform: rotate(90deg);
  }
  .accordion-panel.sd-active .sublist-toggler::after {
    transform: rotate(180deg);
  }
}
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.sd-loader-hidden {
  display: none;
}

#sd-loader-form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
#sd-loader-form #sd-loader-circle {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--color-primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.hero .banner {
  position: relative;
  justify-content: center;
}
.hero .banner-bg-image {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  /*&::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(255,255,255,0.1) 65%);
  }*/
}
.hero .banner-bg-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /*animation-name: banner-zoom;*/
  animation-duration: 26s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.hero .banner-content {
  align-self: center;
  color: var(--color-highlight);
  text-shadow: 1px 1px var(--color-secondary);
}
.hero .headline {
  margin: unset;
  background-color: var(--color-secondary-alpha);
  padding: 20px 25px;
}
.hero .banner-subtext {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}
.hero .banner-button {
  margin-top: 30px;
}

@media screen and (max-width: 1023px) {
  main {
    top: 70px;
    position: relative;
  }
  .hero .banner {
    height: calc(100vh - 70px);
  }
  .hero .banner-headline {
    font-size: 36px;
  }
}
@media screen and (min-width: 1024px) {
  .hero .banner {
    height: calc(100vh - 130px);
  }
  .hero .banner-headline {
    font-size: 64px;
  }
}
@media screen and (max-height: 480px) {
  .hero .banner {
    height: 120vh;
  }
  .hero .banner-image {
    height: 120vh;
  }
  .hero .banner-image::before {
    height: 120vh;
  }
}
.banner-links .head {
  text-align: center;
}
.banner-links .image img {
  max-width: 250px;
  max-height: 250px;
  height: auto;
  width: auto;
}
.banner-links .links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.text-field {
  position: relative;
}
.text-field p {
  margin-bottom: 20px;
}
.text-field h2 {
  background-image: linear-gradient(to left, #212121, #cc0000);
  background-clip: text;
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  width: 100%;
}

.product-box {
  width: 300px;
  height: 500px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0 auto;
}
.product-box .image-container {
  width: 100%;
  height: 30%;
}
.product-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-box .product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  padding-top: 20px;
}
.product-box .product-info .title {
  height: 2.6em;
}
.product-box .product-info .description {
  flex-grow: 2;
  text-align: unset;
  overflow: hidden;
}
.contact-section {
  padding-top: 80px;
}
.contact-section .contact-content {
  margin-top: 30px;
}
.contact-section .contact-image {
  display: flex;
  align-items: center;
}
.contact-section .contact-image .sub-headline {
  margin-bottom: 50px;
}
.contact-section .contact-image picture {
  display: inherit;
}
.contact-section .contact-image img {
  max-width: 415px;
  max-height: 450px;
  width: auto;
  height: auto;
}
.contact-section .section-title {
  text-align: center;
}
.contact-section .section-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
}
.contact-section .boxes {
  display: flex;
  flex-direction: column;
}
.contact-section .form-wrapper {
  padding: unset;
}

@media screen and (max-width: 1023px) {
  .contact-section .boxes {
    flex-direction: column;
    align-items: center;
  }
  .contact-section .contact-image {
    flex-direction: column;
  }
  .contact-section .sub-headline {
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .contact-section .boxes {
    justify-content: space-around;
  }
  .contact-section .contact-image {
    text-align: left;
  }
  .contact-section .contact-image .sub-headline {
    text-align: left;
    max-width: 350px;
    order: 2;
  }
  .contact-section .sub-headline {
    font-size: 22px;
    margin-left: 50px;
  }
}
@keyframes slider-scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
.slider {
  position: relative;
  overflow: hidden;
  height: 250px;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 10%;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  position: absolute;
  display: flex;
  justify-content: space-around;
  min-width: 200%;
  animation-name: slider-scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.slider .slide {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 250px;
}
.slider .img-wrapper {
  display: flex;
  justify-content: center;
}
.slider img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 100px;
}
.slider .technology-name {
  text-align: center;
}
.slider .technology-header {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1023px) {
  .slider .technology-header {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(150%) blur(10px);
}
.form-modal.modal-visible {
  display: flex;
}
.form-modal .modal-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}
.form-modal .modal-content {
  display: flex;
  flex-direction: column;
  background-color: #F5F6F7;
  margin: 0 10px;
  border-radius: 25px;
}
.form-modal .modal-title {
  margin-bottom: 5px;
  font-weight: 700;
}
.form-modal .modal-close {
  border-top: 1px solid grey;
  padding: 15px;
  text-align: center;
  color: #007aff;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .form-modal {
    font-size: 14px;
  }
}
.form-box {
  max-width: 900px !important;
}

form {
  accent-color: var(--color-notification-success);
}
form .captcha > div {
  display: flex;
  flex-direction: column;
}
form .captcha > div input {
  order: 3;
  max-width: 270px;
}
form .captcha > div label {
  order: 1;
}
form .captcha > div .captcha-img-section {
  display: flex;
}
form .form-row {
  width: 100%;
  margin: 25px 0;
}
form .invalid-feedback {
  padding-left: 16px;
  order: 4;
  color: #dc3545;
}
form .check > div {
  display: flex;
  flex-direction: column;
}
form .check > div label {
  max-width: 90%;
}
form .check > div input {
  width: 25px;
  height: 25px;
}
form input:not([type=checkbox]), form textarea, form select {
  width: 100%;
  border: 1px solid var(--color-secondary);
  font-size: 18px;
  padding: 8px;
}
form input, form textarea {
  margin-top: 10px;
}
form textarea {
  resize: none;
  height: 200px;
}
form label.required::after, form legend.required::after {
  content: " *";
  color: var(--color-notification-warning);
}
form .form-mandatory::before {
  content: "*";
  color: var(--color-notification-warning);
}
form .refresh-icon {
  font-size: 64px;
  line-height: 42px;
  cursor: pointer;
  margin-left: 5px;
}
form .form-control.is-invalid, form .was-validated .form-control:invalid, form .is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border: 1px solid #dc3545;
}

.download-section .headline {
  margin-bottom: 15px;
}
.download-section .tex-field {
  margin-bottom: 15px;
}
.download-section a {
  display: block;
}
.download-section a:not(:last-child) {
  margin-bottom: 15px;
}
.download-section a::before {
  content: url("../../../images/pdf.svg");
  width: 25px;
  height: auto;
  display: inline-block;
}

@media screen and (max-width: 1023px) {
  .download-section a {
    font-size: 18px;
  }
}
.timeline {
  position: relative;
}
.timeline::before {
  content: "";
  background-color: var(--color-secondary);
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
}
.timeline .timeline-item {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 400px;
  height: fit-content;
  background-color: #fff;
}
.timeline .timeline-item:not(:last-child) {
  margin-bottom: 30px;
}
.timeline .timeline-element-year {
  padding: 15px 15px;
  background-color: var(--color-secondary);
  color: var(--color-text-light);
  font-weight: 800;
  font-size: 22px;
}
.timeline .timeline-element-description {
  padding: 15px 15px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.timeline .timeline-element-image {
  display: flex;
  justify-content: center;
  max-height: 350px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.timeline .timeline-element-image picture {
  display: inherit;
  justify-content: inherit;
}
.timeline .timeline-element-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1023px) {
  .timeline {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .timeline {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    gap: 0 90px;
  }
  .timeline .timeline-item:nth-child(odd) {
    grid-column: left;
  }
  .timeline .timeline-item:nth-child(odd) .timeline-element-year::after {
    border-left-width: 14px;
    border-left-style: solid;
    left: 100%;
  }
  .timeline .timeline-item:nth-child(odd) .timeline-element-year::before {
    right: -52px;
  }
  .timeline .timeline-item:nth-child(even) {
    grid-column: right;
    margin-top: 90px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-element-year::after {
    border-right-width: 14px;
    border-right-style: solid;
    right: 100%;
  }
  .timeline .timeline-item:nth-child(even) .timeline-element-year::before {
    left: -52px;
  }
  .timeline .timeline-element-year::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-color: var(--color-secondary);
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
  }
  .timeline .timeline-element-year::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-radius: 50%;
    top: 21px;
    border: 7px solid var(--color-primary);
  }
}
section:not(.hero, .section-odd) {
  background-color: var(--color-section-even);
}
section.section-odd {
  background-color: var(--color-section-odd);
}

@media screen and (max-width: 1023px), (hover: none) {
  .nav-toggler {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .nav-toggler:after, .nav-toggler:before, .nav-toggler .nav-toggler-icon {
    background-color: var(--color-text-light);
    display: block;
    height: 3px;
    margin-top: 7px;
    margin-bottom: 7px;
    transition: transform 0.5s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    opacity: 1;
    margin-left: auto;
  }
  .nav-toggler .nav-toggler-icon {
    width: 100%;
  }
  .nav-toggler:after {
    content: "";
    width: 100%;
  }
  .nav-toggler:before {
    content: "";
    width: 100%;
  }
  .nav-main .nav-toggler.sd-active:before {
    width: 100%;
    transform: translateY(10px) rotate(135deg);
  }
  .nav-main .nav-toggler.sd-active:after {
    width: 100%;
    transform: translateY(-10px) rotate(-135deg);
  }
  .nav-main .nav-toggler.sd-active .nav-toggler-icon {
    transform: scale(0);
    opacity: 0;
  }
}
header {
  height: 70px;
  color: var(--color-text-light);
  background-color: var(--color-header-bg);
}
header .header-wrapper {
  flex-direction: row;
}
header .header-wrapper .logo-link {
  display: flex;
  align-items: center;
  height: 100%;
}
header .header-wrapper .logo-link picture {
  display: inherit;
}
header .header-wrapper .logo-link img {
  width: auto;
  height: auto;
  max-width: 35vw;
  max-height: 40px;
  margin-right: 20px;
}

@media screen and (max-width: 1023px), (hover: none) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  header .header-wrapper {
    justify-content: flex-start;
    padding-right: 70px;
  }
  header .header-contact {
    display: none;
  }
  .nav-wrapper {
    position: fixed;
    height: 70px;
    top: 0;
    right: 0;
    z-index: 2;
    padding-right: 10px;
  }
  .nav-wrapper nav {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    color: var(--color-text-light);
  }
  .nav-wrapper nav .menu-main {
    position: fixed;
    overflow-y: auto;
    z-index: 1;
    width: 400px;
    height: calc(100% - 70px);
    max-width: 100%;
    top: 70px;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    transform: translateX(110%);
    transition: transform 0.5s ease-out;
    background-color: var(--color-header-nav-mobile-main);
    box-shadow: 0 6px 6px 2px #b0b0b0;
  }
  .nav-wrapper nav .menu-main.sd-active {
    transform: translateX(0%);
  }
  .nav-wrapper nav .list-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .nav-wrapper nav .list-item.list-item-active > .list-link, .nav-wrapper nav .list-item:hover > .list-link, .nav-wrapper nav .list-item .list-item-active > .list-link, .nav-wrapper nav .list-item :hover > .list-link {
    color: var(--color-primary);
  }
  .nav-wrapper nav .list-item .list-link {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    max-width: 75%;
  }
  .nav-wrapper nav .sublist {
    width: 100%;
    background-color: var(--color-header-nav-mobile-sub);
  }
  .nav-wrapper nav .sublist .list-link {
    max-width: unset;
    padding-left: 20px;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  header .header-wrapper {
    justify-content: space-between;
  }
  .nav-wrapper {
    position: sticky;
    top: 0;
    height: 60px;
    z-index: 10;
    background-color: var(--color-primary);
    color: var(--color-text-dark);
    font-size: 20px;
    font-weight: 500;
  }
  .nav-wrapper .nav-main {
    height: 100%;
  }
  .nav-wrapper .menu-main {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  .nav-wrapper .list-items-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .nav-wrapper .list {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }
  .nav-wrapper .list .list-item {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-left: 10px;
  }
  .nav-wrapper .list .list-item:hover + .menu-overlay {
    display: block;
  }
  .nav-wrapper .list .list-item.list-item-active > .list-link, .nav-wrapper .list .list-item:hover > .list-link, .nav-wrapper .list .list-item .list-item-active > .list-link, .nav-wrapper .list .list-item :hover > .list-link {
    color: var(--color-text-light);
  }
  .nav-wrapper .list .list-item .list-link {
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 100%;
  }
  .nav-wrapper .list .list-item:not(:last-child) {
    margin-right: 10px;
  }
  .nav-wrapper .list .list-item:not(:last-child) .list-link {
    padding-right: 10px;
  }
  .nav-wrapper .list .list-item .list-toggler {
    display: none;
  }
  .nav-wrapper .dropdown:hover .sublist {
    height: 100%;
  }
  .nav-wrapper .sublist {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    left: 0;
    right: 0;
    height: 0;
    top: 70px;
    background-color: var(--color-header-nav-desktop-sub);
    color: var(--color-text-light);
    transition: height 0.3s ease-out;
  }
  .nav-wrapper .sublist .list-items-wrapper {
    justify-content: center;
  }
}
footer {
  padding-top: 15px;
  padding-bottom: 45px;
  border-top: 1px solid;
  background-color: var(--color-footer);
  color: var(--color-text-light);
}
footer .top-part:not(:last-child) {
  margin-right: 15px;
}
footer .top-part a {
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
}
footer .bottom-part {
  margin-top: 15px;
}
footer .bottom-part a {
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
}
footer .footer-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
footer .social-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0;
}
footer .social-links img {
  height: auto;
  width: auto;
  max-width: 40px;
  max-height: 40px;
}
footer .social-link:not(:last-child) {
  margin-right: 15px;
}
footer .footer-headline {
  margin-top: 35px;
  margin-bottom: 5px;
}
footer a:hover {
  color: var(--color-primary);
}
footer .nav-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
footer address div {
  padding-top: 2px;
  padding-bottom: 2px;
}
footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
footer .legal-list {
  display: flex;
  flex-wrap: wrap;
}
footer .legal-list-item:not(:last-child) {
  margin-right: 15px;
}
footer .sd-link {
  width: 100%;
}
footer .about {
  text-align: justify;
}

@media screen and (max-width: 1023px) {
  footer {
    margin-top: 70px;
  }
}
.flash {
  position: fixed;
  z-index: 5;
}
.flash .close {
  margin-left: 15px;
  color: red;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.references-header .headline {
  text-align: center;
}

.hero.reference .headline {
  color: var(--color-primary);
}

.reference-card {
  height: 400px;
  perspective: 900px;
  cursor: default;
}
.reference-card .content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 500ms ease-out;
  transform-style: preserve-3d;
}
.reference-card .front, .reference-card .back {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  backface-visibility: hidden;
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
  border-radius: 25px;
  background-color: #fff;
}
.reference-card .front:after {
  content: "⤺";
  position: absolute;
  top: 0px;
  right: 20px;
  width: 60px;
  height: 60px;
  font-size: 60px;
  font-weight: 600;
}
.reference-card .back {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 30px;
  align-items: center;
  transform: rotateY(180deg);
  background-color: var(--color-secondary);
  color: var(--color-text-light);
}
.reference-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.reference-card .reference-card-text {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.reference-card .reference-card-back-text {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}
.reference-card .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(204, 0, 0, 0.7490196078);
  height: auto;
  width: 100%;
}
.reference-card .reference-card-title {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 10px;
  font-weight: 600;
  text-shadow: 2px 2px 0 #212121;
  color: var(--color-text-light);
}
.reference-card:hover .content {
  transform: rotateY(-180deg) translateZ(-1px);
}

.references-content {
  width: 100%;
}

.references-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .reference-card {
    margin: 30px 0;
    width: 100%;
    max-width: 600px;
  }
  .reference-card .reference-card-title {
    font-size: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .reference-card {
    width: 400px;
    margin: 30px;
  }
  .reference-card .reference-card-title {
    font-size: 38px;
  }
}
.reference-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.reference-images .reference-image {
  display: flex;
  justify-content: center;
  max-width: 400px;
  max-height: 300px;
  margin: 35px;
}
.reference-images .reference-image picture {
  display: inherit;
  justify-content: inherit;
}
.reference-images .reference-image img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.reference-title {
  align-self: flex-start;
  padding-left: unset;
  padding-right: unset;
}

.reference-title, .challenge-title, .solution-title {
  margin-bottom: 12px;
}

.reference-images-headline {
  margin-bottom: 25px;
}

.reference-header, .reference-text {
  max-width: 900px !important;
}

.reference-solution, .reference-challenge {
  margin-top: 80px;
}