@include mobileAndTouchOnly {
  $frameWidth : 27px;
  $burger-cross-width: calc($frameWidth - 10px);
  $burger-cross-height: 2px;

  .sublist-toggler {
    display: flex;
    width: $frameWidth;
    height: $frameWidth;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: $radius-round;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      display: block;
      background-color: var(--color-primary);
      content: "";
      border-radius: 2px;
    }

    &::after {
      width: $burger-cross-width;
      height: $burger-cross-height;
      transition: transform 0.3s ease-in-out;
    }

    &::before {
      width: $burger-cross-height;
      height: $burger-cross-width;
      opacity: 1;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  }

  .accordion-panel {
    &.sd-active {
      .sublist-toggler {
        &::before {
          transform: rotate(90deg);
        }

        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}