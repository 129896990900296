@mixin container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1600px;

  @include mobileOnly {
    padding-left: $padMargDefault;
    padding-right: $padMargDefault;
  }

  @include tabletAndBigger {
    padding-left: $padMargDesktop;
    padding-right: $padMargDesktop;
  }
}