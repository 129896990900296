// initial opacity (deleted, if no IntersectionObserver present in browser, so clients will see all the elements without animation
[data-animation-target] {
  opacity: 0;
}

// special case alternating elements (horizontal fly in)
[data-animation-animation-name-value="alternating-horizontal"] {
  [data-animation-target] {
    &.sd-observed {
      &:nth-child(odd) {
        animation-name: anim--from-left;
      }
      &:nth-child(even) {
        animation-name: anim--from-right;
      }
    }
  }
}

// special case alternating elements (horizontal fly in reverse)
[data-animation-animation-name-value="alternating-horizontal-reverse"] {
  [data-animation-target] {
    &.sd-observed {
      &:nth-child(odd) {
        animation-name: anim--from-right;
      }
      &:nth-child(even) {
        animation-name: anim--from-left;
      }
    }
  }
}

// special case alternating elements (vertical fly in)
[data-animation-animation-name-value="alternating-vertical"] {
  [data-animation-target] {
    &.sd-observed {
      &:nth-child(odd) {
        animation-name: anim--from-bottom;
      }
      &:nth-child(even) {
        animation-name: anim--from-top;
      }
    }
  }
}


// available keyframes (can be edited, extended, added...)
// use in template on controller level as data-animation-animation-name-value="xxx"
@keyframes anim--from-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes anim--from-right {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes anim--from-bottom {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes anim--appear {
  100% {
    opacity: 1;
  }
}

@keyframes banner-zoom {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes anim--short-hop {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


/* flip card indicator */
@keyframes flip-card {
  50% {
    rotate: -5deg;
  }
  75% {
    rotate: 5deg;
  }
  100% {
    rotate: 0deg;
  }
}