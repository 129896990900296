/* fonts */
/**********************************************************************************************************************/

/*
text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
-webkit-text-stroke: 0.1px #000;
*/

// default text
$font-text-default: Roboto, sans-serif;

// special
$font-text-special: Roboto, sans-serif;

// extraordinary
$extraordinary: Roboto, sans-serif;

// headline
$font-text-headline: $font-text-special;

body {
  font-family: $font-text-default;
  font-size: 19px;
  color: var(--color-text-dark);
}

@include mobileOnly {
  main {
    font-size: 16px;
  }
}

h1, h2, h3 ,h4, h5, h6 {
  font-family: $font-text-headline;
}

.headline {
  margin-top: 12px;
  margin-bottom: 32px;
}

.sub-headline {
  font-weight: 600;
  color: var(--color-text-grey);
  text-align: center;
}

.highlight {
  //font-family: $font-text-special;
  //color: var(--color-highlight);
}

.highlight {
  @include highlight;
}

::selection {
  color: var(--color-text-light);
  background-color: var(--color-highlight)
}

p {
  text-align: justify;
}

main {
  li {
    list-style: inside;
  }
}


/* font sizes */
/**********************************************************************************************************************/

@include mobileOnly {

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1.1rem;
  }

  .sub-headline {
    font-size: 22px;
  }

}

@include tabletAndBigger {

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 54px;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 1.4rem;
  }

  h6 {
    font-size: 1.2rem;
  }

  .sub-headline {
    font-size: 30px;
  }

}