.text-field {
  position: relative;

  p {
    margin-bottom: 20px;
  }
  h2{
    background-image: linear-gradient(to left, #212121, #cc0000);
    background-clip: text;

  }
}