$btn-padding-top-bottom: 9px;
$btn-padding-left-right: 2*$btn-padding-top-bottom;

@mixin btn-base {
  @include mobileOnly {
    width: 100%;
    max-width: 390px;
  }

  @include tabletAndBigger {
    min-width: 160px;
    padding-left: $btn-padding-left-right;
    padding-right: $btn-padding-left-right;
  }

  padding-bottom: $btn-padding-top-bottom;
  padding-top: $btn-padding-top-bottom;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: all .3s;
  font-size: 18px;
  border-radius: $radius-btn;
}

@mixin btn-creator {
  /* any link that has a class */
  &[class] {
    @include btn-base;
  }

  &[class*="-full"] {
    &[class*="-highlight"]  {
      background-image: $gradient-01;
      color: var(--color-btn-dark-text);
      text-shadow: 1px 1px 0 #212121;
      border: none;
      padding-bottom: $btn-padding-top-bottom + 1px;
      padding-top: $btn-padding-top-bottom + 1px;
      font-weight: 600;

      @include tabletAndBigger {
        padding-left: $btn-padding-left-right + 1px;
        padding-right: $btn-padding-left-right + 1px;
      }

      @media (hover: hover) {
        &:hover {
          box-shadow: $shadow-01;
          transform: scale(1.05);
        }
      }
    }

    &[class*="-dark"]  {
      background-color: var(--color-btn-dark-bg);
      border-color: var(--color-btn-dark-border);
      color: var(--color-btn-dark-text);
      font-weight: 600;
      @media (hover: hover) {
        &:hover {
          background-color: var(--color-btn-dark-bg-lightened);
          border-color: var(--color-btn-dark-border-lightened);
          box-shadow: $shadow-01;
          transform: scale(1.05);
        }
      }
    }

    &[class*="-light"] {
      background-color: var(--color-btn-light-bg);
      border-color: var(--color-btn-light-border);
      color: var(--color-btn-light-text);
      @media (hover: hover) {
        &:hover {
          background-color: var(--color-btn-light-bg-darkened);
          border-color: var(--color-btn-light-border-darkened);
          box-shadow: $shadow-01;
          transform: scale(1.05);
        }
      }
    }
  }

  &[class*="-link"] {
    border-color: transparent;
    &[class*="-dark"] {
      color: var(--color-text-dark);
      font-weight: 600;
      @media (hover: hover) {
        &:hover {
          color: var(--color-btn-dark-bg);
        }
      }
    }

    &[class*="-light"] {
      color: var(--color-link-light);
      text-shadow: 1px 1px 0 #212121;
      @media (hover: hover) {
        &:hover {
          color: var(--color-btn-dark-bg);
        }
      }
    }
  }
}