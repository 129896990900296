@include mobileAndTouchOnly {
  $toggler-line-height: 3px;
  $toggler-line-margin: 7px;
  $toggler-translate-sum: $toggler-line-height + $toggler-line-margin;
  $toggler-width: 40px;

  .nav-toggler {
    width: $toggler-width;
    height: $toggler-width;
    cursor: pointer;

    &:after, &:before, .nav-toggler-icon {
      background-color: var(--color-text-light);
      display: block;
      height: $toggler-line-height;
      margin-top: $toggler-line-margin;
      margin-bottom: $toggler-line-margin;
      transition: transform 0.5s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
      opacity: 1;
      margin-left: auto;
    }
    & .nav-toggler-icon {
      width: 100%;
    }
    &:after {
      content: "";
      width: 100%;
    }
    &:before {
      content: "";
      width: 100%;
    }
  }

  .nav-main {
    .nav-toggler {
      &.sd-active {
        &:before {
          width: 100%;
          transform: translateY($toggler-translate-sum) rotate(135deg);
        }

        &:after {
          width: 100%;
          transform: translateY(-$toggler-translate-sum) rotate(-135deg);
        }

        .nav-toggler-icon {
          transform: scale(0);
          opacity: 0;
        }
      }
    }
  }
}
