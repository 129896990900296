section {
  @if($oddSections) {
    &:not(.hero, .section-odd) {
      background-color: var(--color-section-even);
    }
    &.section-odd {
      background-color: var(--color-section-odd);
    }
  } @else {
    &:not(.hero) {
      background-color: var(--color-section-even);
    }
  }
}