footer {
  .top-part {
    &:not(:last-child) {
      margin-right: 15px;
    }

    & a {
      text-decoration: underline;
      text-decoration-color: var(--color-primary);
    }
  }

  .bottom-part {
    margin-top: 15px;
    & a {
      text-decoration: underline;
      text-decoration-color: var(--color-primary);
    }
  }

  padding-top: 15px;
  padding-bottom: 45px;
  border-top: 1px solid;
  background-color: var(--color-footer);
  color: var(--color-text-light);


  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 0;

    img {
      height: auto;
      width: auto;
      max-width: 40px;
      max-height: 40px;
    }
  }
  .social-link {
    &:not(:last-child) {
      margin-right: 15px;
    }

  }

  .footer-headline {
    margin-top: 35px;
    margin-bottom: 5px;
  }

  a {
    &:hover {
      @include highlight;
    }
  }

  .nav-link {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  address {
    & div {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
  }

  .legal-list {
    display: flex;
    flex-wrap: wrap;
  }

  .legal-list-item {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  .sd-link {
    width: 100%;
  }
  .about {
    text-align: justify;
  }
}

@include mobileOnly {
  footer {
    margin-top: $headerHeight
  }
}